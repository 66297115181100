<template>
  <div>
    <!-- {{ axisInfo }} -->
    <div ref="chart" id="chart3d"></div>

    <div id="chart3DTooltip" class="customizedTooltip">
      <div class="customizedTooltipRow">
        <div
          class="v-icon notranslate mdi mdi-axis-x-arrow customizedTooltipIcon"
        ></div>
        <div class="customizedTooltipItem">
          {{ this.dataTooltip3D.axisX.name }}
        </div>
        <div class="customizedTooltipItemValue">
          {{ this.dataTooltip3D.axisX.main }}
        </div>
        <div class="customizedTooltipItemJitter">
          {{
            this.dataTooltip3D.axisX.tolerance
              ? "±" + this.dataTooltip3D.axisX.tolerance
              : ""
          }}
        </div>
      </div>
      <div class="customizedTooltipRow">
        <div
          class="v-icon notranslate mdi mdi-axis-x-arrow customizedTooltipIcon"
        ></div>
        <div class="customizedTooltipItem">
          {{ this.dataTooltip3D.axisY.name }}
        </div>
        <div class="customizedTooltipItemValue">
          {{ this.dataTooltip3D.axisY.main }}
        </div>
        <div class="customizedTooltipItemJitter">
          {{
            this.dataTooltip3D.axisY.tolerance
              ? "±" + this.dataTooltip3D.axisY.tolerance
              : ""
          }}
        </div>
      </div>
      <div class="customizedTooltipRow">
        <div
          class="v-icon notranslate mdi mdi-axis-x-arrow customizedTooltipIcon"
        ></div>
        <div class="customizedTooltipItem">
          {{ this.dataTooltip3D.axisZ.name }}
        </div>
        <div class="customizedTooltipItemValue">
          {{ this.dataTooltip3D.axisZ.main }}
        </div>
        <div class="customizedTooltipItemJitter">
          {{
            this.dataTooltip3D.axisZ.tolerance
              ? "±" + this.dataTooltip3D.axisZ.tolerance
              : ""
          }}
        </div>
      </div>
      <div v-if="this.dataTooltip3D?.axisT?.name" class="customizedTooltipRow">
        <div
          class="v-icon notranslate mdi mdi-axis-x-arrow customizedTooltipIcon"
        ></div>
        <div class="customizedTooltipItem">
          {{ this.dataTooltip3D.axisT.name }}
        </div>
        <div class="customizedTooltipItemValue">
          {{ this.dataTooltip3D.axisT.main }}
        </div>
        <div class="customizedTooltipItemJitter">
          {{
            this.dataTooltip3D.axisT.tolerance
              ? "±" + this.dataTooltip3D.axisT.tolerance
              : ""
          }}
        </div>
      </div>
    </div>
    "
  </div>
</template>

<script>
import * as Plotly from "plotly.js-dist-min";
import { smartPruneNumber } from "@/utils/number";
import { gradient } from "@/utils/color";
export default {
  data: () => ({
    selectedPoint: [],
    visiblePoints: [],
    showTooltip3D: true,
    LastTimeDataUpdateWhitZoom: 0,
    dataTooltip3D: {
      axisX: {
        name: "",
        main: 0,
        tolerance: 0,
      },
      axisY: {
        name: "",
        main: 0,
        tolerance: 0,
      },
      axisZ: {
        name: "",
        main: 0,
        tolerance: 0,
      },
    },
    isControllerBoxOpen: false,
    zoomEnabled: false,
    size: 2.5,
    color: "#00859A",
    initialData: {},
    lastPointMouseOver: "#00859A",
    positionCamera: { x: 1.5, y: 1.5, z: 1.5 },
  }),
  props: {
    dataChart: {
      type: Object,
      default: () => ({}),
    },
    allChartData: {
      type: [Object, Array],
    },
    markerFillColor: { type: Object, default: () => ({}) },
    axisInfo: { type: [], default: () => [] },
    gradientEnd: { type: String, default: "" },
    actionSelectPoint: { type: Boolean, default: false },
  },
  watch: {
    dataChart: {
      handler() {
        this.createChart();
      },
      deep: true,
    },
    markerFillColor: {
      handler() {
        this.createChart();
      },
      deep: true,
    },
    axisInfo: {
      handler() {
        this.createChart();
      },
      deep: true,
    },
  },
  computed: {
    createChartData() {
      const arrayOfData = this.dataChart.datasets;
      let finalData = [];
      arrayOfData.forEach((trace) => {
        const normal = this.normalizeData(trace.data);
        let colors = [];
        if (normal.t.length != 0) {
          colors = this.getPointBgColor(normal.t);
        } else {
          colors = this.markerFillColor.markerFillColor;
        }

        const a = {
          x: normal.x,
          y: normal.y,
          z: normal.z,
          fullPackData: trace.data,
          mode: "markers",
          name: trace.label,
          marker: {
            size: trace.radius,
            color: this.setColors(trace, colors),

            line: {
              color: this.markerFillColor.markerBorderColor || "#35d1e8",
              width: this.markerFillColor.markerThickness || 1,
            },
            opacity: 1,
          },
          symbol: "square",
          type: "scatter3d",
          scene: "scene",
          hovertemplate: `<extra></extra>`,
        };
        finalData.push(a);
      });

      return finalData;
    },
  },

  methods: {
    setColors(trace, colors) {
      if (trace.label === "Original Space") {
        return "#f0f0f090";
      }
      if (trace.label === "Filtered Space") {
        return colors;
      }
      return trace.backgroundColor;
    },
    normalizeData(data) {
      let x = [];
      let y = [];
      let z = [];
      let t = [];
      data.forEach((item) => {
        x.push(item.x);
        y.push(item.y);
        z.push(item.z);
        t.push(item.t);
      });
      return { x, y, z, t };
    },

    toggleControllerBox() {
      this.isControllerBoxOpen = !this.isControllerBoxOpen;
    },

    setTooltipData(data) {
      this.dataTooltip3D = {
        axisX: {
          name: this.axisInfo[0]?.title,
          main: smartPruneNumber(data.x),
          tolerance: smartPruneNumber((data.max_x - data.min_x) / 2),
        },
        axisY: {
          name: this.axisInfo[1]?.title,
          main: smartPruneNumber(data.y),
          tolerance: smartPruneNumber((data.max_z - data.min_z) / 2),
        },
        axisZ: {
          name: this.axisInfo[2]?.title,
          main: smartPruneNumber(data.z),
          tolerance: smartPruneNumber((data.max_z - data.min_z) / 2),
        },
        axisT: {
          name: this.axisInfo[3]?.title || "",
          main: smartPruneNumber(data.t) || 0,
          tolerance: smartPruneNumber((data.max_t - data.min_t) / 2) || 0,
        },
      };

      this.showTooltip3D = true;
    },

    createChart() {
      const data = this.createChartData;

      const layout = {
        hovermode: "closest",
        scene: {
          xaxis: {
            title: `X: ${this.axisInfo[0]?.title || ""}`,
            color: "#0E5661",
            spikecolor: "#1fe5bd",
            spikethickness: 2,
            showbackground: true,
            backgroundcolor: "#f6f6f6",
          },
          yaxis: {
            title: `Y: ${this.axisInfo[1]?.title || ""}`,
            color: "#0E5661",
            spikecolor: "#1fe5bd",
            spikethickness: 2,
            showbackground: true,
            backgroundcolor: "#f6f6f6",
          },
          zaxis: {
            title: `Z: ${this.axisInfo[2]?.title || ""} `,
            color: "#0E5661",
            spikecolor: "#1fe5bd",
            spikethickness: 2,
            showbackground: true,
            backgroundcolor: "#f6f6f6",
          },
          // camera: this.positionCamera,
          bgcolor: "#fff",
        },
        showlegend: true,
        legend: {
          orientation: "h",
          x: 0.3,
          y: 0.1,
          bgcolor: "#E2E2E2",
          bordercolor: "#FFFFFF",
          borderwidth: 2,
          font: {
            family: "Arial, sans-serif",
            size: 12,
            color: "#000",
          },
        },
        plot_bgcolor: "#f6f6f6",
        dragmode: "orbit",
        margin: {
          l: 0,
          r: 0,
          t: 0,
          b: 0,
        },
      };

      const config = {
        displayModeBar: false, // نمایش نوار ابزار
      };

      Plotly.newPlot("chart3d", data, layout, config).then((chart) => {
        chart.on("plotly_hover", (eventData) => {
          this.hoverEveryData(eventData);
        });
        chart.on("plotly_unhover", () => {
          const tooltip = document.getElementById("chart3DTooltip");
          tooltip.style.visibility = "hidden";
          tooltip.style.scale = 0;
          this.showTooltip3D = false;
        });
      });
    },
    hoverEveryData(eventData) {
      const useData = eventData.points[0].data.fullPackData;

      const culms = Object.keys(this.allChartData.aggregations);
      let pointMouseOver = eventData.points[0].pointNumber;

      const dt = useData[pointMouseOver];
      if (pointMouseOver == this.lastPointMouseOver) {
        return;
      }
      this.lastPointMouseOver = pointMouseOver;

      const x = {},
        y = {},
        z = {};
      x[this.axisInfo[0].name] = { lte: dt.max_x, gte: dt.min_x };
      y[this.axisInfo[1].name] = { lte: dt.max_y, gte: dt.min_y };
      z[this.axisInfo[2].name] = { lte: dt.max_z, gte: dt.min_z };
      let localFilters = [x, y, z];

      if (this.axisInfo[3]) {
        const t = {};
        t[culms[3]] = { lte: dt.max_t, gte: dt.min_t };
        localFilters.push(t);
      }

      this.$emit("onMarkerMouseOver", localFilters);

      this.setTooltipData(dt);

      const tooltip = document.getElementById("chart3DTooltip");
      tooltip.style.visibility = "visible";
      tooltip.style.scale = 1;
    },
    getPointBgColor(t) {
      const columns = this.axisInfo
        .filter((x) => x !== null)
        .map((item) => item?.name);
      const tStat = this.allChartData.aggregations[columns[3]];
      if (tStat?.min === undefined) {
        return Array(t.length).fill(
          this.markerFillColor.markerFillColor || "#35d1e8"
        );
      }

      let colorArray = [];
      t.forEach((item) => {
        const transform = (item - tStat.min) / (tStat.max - tStat.min);
        const start = this.markerFillColor.markerFillColor || "#0393a8";
        const end = this.gradientEnd;
        const color = gradient(transform, start, end);
        colorArray.push(color + "95");
      });

      return colorArray;
    },
  },
  mounted() {
    const tooltip = document.getElementById("chart3DTooltip");
    tooltip.style.visibility = "hidden";
    const dchart = document.getElementById("chart3d");
    dchart.addEventListener("mouseover", () => {
      tooltip.style.visibility = "hidden";
    });
    document.addEventListener("mousemove", (event) => {
      tooltip.style.left = event.clientX - 370 + "px";
      tooltip.style.top = event.clientY - 220 + "px";
    });

    // this.showTooltip3D = false;
  },
};
</script>

<style scoped>
#modebar-cc2a38 {
  display: none !important;
}

#chart3d {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controller-box {
  position: fixed;
  top: 60px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 200px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  height: 0;
}

.controller-box.open {
  height: 200px;
  z-index: 1000;
  padding: 10px;
}

.controller-box label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
}

.controller-box input[type="checkbox"],
.controller-box input[type="range"],
.controller-box input[type="color"] {
  margin-top: 5px;
  cursor: pointer;
}

.controller-box input[type="range"] {
  width: 100%;
}

.controller-box input[type="color"] {
  padding: 5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background: #333;
  color: #fff;
}

.toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #00859a;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #006f7a;
}

#logoHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

#logo {
  padding: 30px;
}

.modebar-container {
  display: none !important;
}

.modebar-container:hover {
  display: none !important;
}

.js-plotly-plot .plotly .modebar {
  position: absolute;
  top: 2px;
  right: 2px;
  display: none !important;
}

/* toooooo */

.customizedTooltip {
  position: absolute;
  z-index: 2;
  border-image: linear-gradient(to right, #9adbe4, #0393a8) 20;
  border-width: 0.5px;
  border-style: solid;
  margin-top: 10px;
  margin-left: 10px;

  border-color: var(--v-primary-base);
  border-radius: 6px;

  min-width: 320px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(3px);
  color: black;
  padding: 6px;
  font-family: KoHo, sans-serif;
  font-size: 12px !important;
}

.customizedTooltip.dark {
  color: white;
  background: #000000cc;
  box-shadow: none;
}

.customizedTooltipRow {
  display: flex;
  padding-top: 3px;
}

.customizedTooltipItem {
  font-weight: 400;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 4px;
  margin-right: auto;
}

.customizedTooltipIcon {
  font-size: 14px !important;
  color: var(--v-primary-base);
}

.customizedTooltipItemValue {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3px;
  font-weight: 700;
  color: var(--v-primary-base);
}

.customizedTooltipItemJitter {
  font-size: 10px !important;
  color: #6b7588;
  width: 60px;
  padding-top: 5px;
  padding-left: 4px;
}

.eeee {
  position: relative;
}
</style>
