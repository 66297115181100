<template>
    <v-menu offset-y v-model="menu" :close-on-content-click="false" class="mt-10" content-class="elevation-0 modal">
        <template v-slot:activator="{ attrs, on }">
            <v-btn color="primary" large icon v-bind="attrs" v-on="on">
                <v-icon>mdi-blur</v-icon>
            </v-btn>
        </template>
        <div style="width: 400px">
            <div class="d-flex flex-row pa-3" style="border-bottom: 1px solid rgb(236, 236, 236)">
                <div class="text-h4">Chart Range Filter</div>
                <v-icon @click="menu = null" class="ml-auto">mdi-close</v-icon>
            </div>
            <v-row class="mx-2 ">
                <v-col cols="12">
                    <RangeSlider class="px-4" id="X" v-on:onChanged="onFilterChanged" :name="dataRangeX.name"
                        :min="dataRangeX.min" :max="dataRangeX.max" :title="dataRangeX.title">
                    </RangeSlider>
                </v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col cols="12">
                    <RangeSlider class="px-4" id="Y" v-on:onChanged="onFilterChanged" :name="dataRangeY.name"
                        :min="dataRangeY.min" :max="dataRangeY.max" :title="dataRangeY.title">
                    </RangeSlider>
                </v-col>
            </v-row>
            <v-row class="mx-2 ">
                <v-col cols="12">
                    <RangeSlider class="px-4" id="Z" v-on:onChanged="onFilterChanged" :name="dataRangeZ.name"
                        :min="dataRangeZ.min" :max="dataRangeZ.max" :title="dataRangeZ.title">
                    </RangeSlider>
                </v-col>
            </v-row>
            <div class="d-flex flex-row py-3" style="border-top: 1px solid rgb(236, 236, 236)">
                <CBtn class="ml-auto" outlined width="132" @click="cancel()">cancel</CBtn>
                <CBtn class="mx-3" width="152" @click="preActionUpdate()">Confirm</CBtn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import CBtn from "@/components/Vuetify/CBtn.vue";

// import rangeSlider from "./rangeSlider.vue";
import RangeSlider from "@/components/Mat/RangeSlider.vue";
export default {
    name: "chartFilter",
    components: { CBtn, RangeSlider },
    data: () => ({
        menu: false,
        dataRangeX: {
            max: 1,
            min: 0,
            range: { range: [0, 1] },
            title: "",
            step: 1,
            name: ""
        },
        dataRangeY: {
            max: 1,
            min: 0,
            range: { range: [0, 1] },
            title: "",
            step: 1,
            name: ''
        },
        dataRangeZ: {
            max: 1,
            min: 0,
            range: { range: [0, 1] },
            title: "",
            step: 1,
            name: ''
        },
        FilterChanged: {
            X: {},
            Y: {},
            Z: {}
        }
    }),
    props: {
        allChartData: {
            type: Object,
            default: () => ({}),
        },
        dataBack: {
            type: Object,
            default: () => ({}),
        },
        axisInfo: { type: [], default: () => [] },
    },

    watch: {
        menu: {
            handler(newVal) {
                if (newVal == true) {
                    this.carateDataForFilter();
                }
            },
            deep: true,
        },
    },
    methods: {
        onFilterChanged(id, name, value) {
            this.FilterChanged[id] = { name: name, range: value }
        },
        carateDataForFilter() {
            const { aggregations } = this.allChartData;
            // const resultCount = result.length;
            let info = {};
            this.axisInfo.forEach((element) => {
                info[element["name"]] = element.title;
            });
            const keys = Object.keys(info);
            const stepX =
                (aggregations[keys[0]].max - aggregations[keys[0]].min) / 1000;
            const stepY =
                (aggregations[keys[1]].max - aggregations[keys[1]].min) / 1000;
            const stepZ =
                (aggregations[keys[2]].max - aggregations[keys[2]].min) / 1000;
            this.dataRangeX = {
                max: aggregations[keys[0]].max + stepX,
                min: aggregations[keys[0]].min - stepX,
                range: [aggregations[keys[0]].min, aggregations[keys[0]].max],
                title: `X: ${info[keys[0]]}`,
                name: keys[0],
                step: stepX,
            };
            this.dataRangeY = {
                max: aggregations[keys[1]].max + stepY,
                min: aggregations[keys[1]].min - stepY,
                range: [aggregations[keys[1]].min, aggregations[keys[1]].max],
                title: `Y: ${this.axisInfo[1].title}`,
                name: keys[1],
                step: stepY,
            };
            this.dataRangeZ = {
                max: aggregations[keys[2]].max + stepZ,
                min: aggregations[keys[2]].min - stepZ,
                range: [aggregations[keys[2]].min, aggregations[keys[2]].max],
                title: `Z: ${this.axisInfo[2].title}`,
                name: keys[2],
                step: stepZ,
            };
            this.FilterChanged = {
                X: { name: this.dataRangeX.name, range: this.dataRangeX.range },
                Y: { name: this.dataRangeY.name, range: this.dataRangeY.range },
                Z: { name: this.dataRangeZ.name, range: this.dataRangeZ.range }
            }
        },
        cancel() {
            this.menu = false;
        },
        preActionUpdate() {
            this.FilterChanged

            let payload = [];

            payload[0] = {
                [this.FilterChanged.X.name]: {
                    gte: this.FilterChanged.X.range[0],
                    lte: this.FilterChanged.X.range[1],
                },
            };
            payload[1] = {
                [this.FilterChanged.Y.name]: {
                    gte: this.FilterChanged.Y.range[0],
                    lte: this.FilterChanged.Y.range[1],
                },
            };
            payload[2] = {
                [this.FilterChanged.Z.name]: {
                    gte: this.FilterChanged.Z.range[0],
                    lte: this.FilterChanged.Z.range[1],
                },
            };

            this.dataBack.columnsFilter = payload;
            this.$emit("onChangedRange");
            this.menu = false;
        },
    },
};
</script>
<style scoped>
.modal {
    /* box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1) !important; */
    box-shadow: 0px 13px 80px 0px rgba(0, 0, 0, 0.1) !important;
    /* box-shadow: 10px 26px 80px 10px rgba(0, 0, 0, 0.5) !important; */
    background-color: white;
    font-weight: 400px;
    font-family: "IBM Plex Sans";
}

.theme--dark .modal {
    background-color: rgb(20, 20, 20);
}

.colorPickerCmd {
    border-radius: 6px;
    padding: 4px 4px 4px 4px;
    box-shadow: 0px 0px 30px 7px rgba(0, 0, 0, 0.07);
}

.colorPickerCmd:hover {
    box-shadow: 0px 0px 30px 12px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    /* background-color:  rgb(245, 245, 245); */
}

.colorSampleBox {
    width: 24px;
    height: 24px;
    border-radius: 6px;
}
</style>
