<template>
  <div class="d-flex flex-column pa-7" style="width: 100%; height: 100%">
    <div class="d-flex flex-row mb-1">

      <div v-if="!chart3DShow" class="leftCol">
        <v-tooltip top>
          <template v-slot:activator="{ on, attr }">
            <div class="zoomModeBtn" :style="zoomMode == 'y' ? 'background-color:#CBCCD0' : ''"
              @click="zoomMode == 'y' ? (zoomMode = 'xy') : (zoomMode = 'y')" v-on="on" v-bind="attr">
              <icon id="pan-vertical" size="17px"></icon>
            </div>
          </template>
          <span>{{
        zoomMode == "y"
          ? "Enable X-Y axis in zooming"
          : "Lock Zoom on Y axis"
      }}
          </span>
        </v-tooltip>
      </div>
      <div class="centerCol d-flex flex-row">
        <SelectAxisBtn btnText="Y" :items="normalDatasetFields" itemText="title" itemValue="name"
          :value="selectedAxis[1]" @onChange="onAxisChange(1, $event)"></SelectAxisBtn>
        <div class="mt-n1 mx-auto d-flex justify-center align-center">
          <ChartHelp></ChartHelp>
          <v-btn icon large color="primary" @click="onResetZoom">
            <icon id="zoom-reset"> </icon>
          </v-btn>
          <v-switch v-if="axisInfo[0]?.title && axisInfo[1]?.title && axisInfo[2]?.title
        " class="switch-3d" v-model="chart3DShow" label="3D" color="secondary" hide-details></v-switch>

          <ChartSettings :makerThickness="makerThickness" :markerFillColor="markerFillColor"
            :markerBorderColor="markerBorderColor" :markerSize="markerSize" @onChanged="onApplyChartSetting">
          </ChartSettings>
          <chartFilter v-if="chart3DShow" @onChangedRange="updateChart" :axisInfo="axisInfo"
            :allChartData="allChartData" :dataBack="dataBack3DChart">
          </chartFilter>
        </div>
      </div>
      <div class=" rightCol pl-6">
        <SelectAxisBtn v-if="!chart3DShow" btnText="Z" :items="normalDatasetFields" itemText="title" itemValue="name"
          :value="selectedAxis[2]" @onChange="onAxisChange(2, $event)"></SelectAxisBtn>
        <SelectAxisBtn v-else btnText="T" :items="normalDatasetFields" itemText="title" itemValue="name"
          :value="selectedAxis[3]" @onChange="onAxisChange(3, $event)"></SelectAxisBtn>
      </div>
    </div>
    <div class="centerRow d-flex flex-row mb-1">
      <div v-if="!chart3DShow" class="leftCol yAxisLabelBox d-flex flex-column mt-2 mb-11">
        <v-spacer></v-spacer>
        <div class="yAxisLabel ml-1">{{ axisInfo[1]?.title || null }}</div>
        <v-spacer></v-spacer>
      </div>
      <div class="centerCol mr-n6" style="position: relative">
        <Scatter v-show="!chart3DShow" ref="myChart" :chart-options="chartOptions" :chart-data="chartData"
          :chart-id="chartGeneralConfig.chartId" :dataset-id-key="chartGeneralConfig.datasetIdKey" :plugins="plugins"
          :css-classes="chartGeneralConfig.cssClasses" :styles="chartGeneralConfig.styles"
          :width="chartGeneralConfig.width" :height="chartGeneralConfig.height">
        </Scatter>
        <d-chart v-show="chart3DShow" :dataChart="chartData" :allChartData="allChartData" :markerFillColor="chartConf3D"
          @onMarkerMouseOver="onMouseOver3D" @actionSelectPoint="actionSelectPoint = false" :axisInfo="axisInfo"
          :actionSelectPoint="actionSelectPoint" :gradientEnd="gradientEnd" :dataBack="dataBack3DChart" />
      </div>
      <div class="rightCol mb-11 pt-2" style="margin-top: 2px">
        <GradientLegend v-if="!chart3DShow" height="100%" width="87px" :min="gradientLegendConfig.min"
          :max="gradientLegendConfig.max" :title="gradientLegendConfig.title" :stepCount="9"
          :gradientStart="markerFillColor" :gradientEnd="gradientEnd">
        </GradientLegend>
        <GradientLegend v-else height="100%" width="87px" :min="gradientLegendConfig3D.min"
          :max="gradientLegendConfig3D.max" :title="gradientLegendConfig3D.title" :stepCount="9"
          :gradientStart="markerFillColor" :gradientEnd="gradientEnd">
        </GradientLegend>
      </div>
    </div>
    <div class="d-flex flex-row mb-1">
      <div v-if="!chart3DShow" class="leftCol">
        <!-- <CTooltip text="Swap X and Y axes"> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <c-btn v-bind="attrs" v-on="on" radius="8px" x-small fab class="swapBtn" @click="swapXY()">
              <icon id="swap-xy" size="18px"></icon>
            </c-btn>
          </template>
          <span>Swap X and Y axes</span>
        </v-tooltip>
        <!-- </CTooltip> -->
      </div>
      <div class="centerCol d-flex flex-row mr-10 justify-space-between">
        <SelectAxisBtn v-if="chart3DShow" btnText="Z" :items="normalDatasetFields" itemText="title" itemValue="name"
          :value="selectedAxis[2]" @onChange="onAxisChange(2, $event)"></SelectAxisBtn>

        <div v-if="!chart3DShow" class="xAxisLabelBox ml-8 mr-2">
          {{ axisInfo[0]?.title || null }}
        </div>
        <!-- <CTooltip :text="zoomMode == 'x' ? 'Enable X-Y axis in zooming' : 'Lock Zoom on X axis'">
        </CTooltip> -->

        <v-tooltip top>
          <template v-slot:activator="{ on, attr }">
            <div v-if="!chart3DShow" class="zoomModeBtn mr-2" :style="zoomMode == 'x' ? 'background-color:#CBCCD0' : ''"
              @click="zoomMode == 'x' ? (zoomMode = 'xy') : (zoomMode = 'x')" v-on="on" v-bind="attr">
              <icon id="pan-horizontal" size="17px"></icon>
            </div>
          </template>
          <span>{{
        zoomMode == "x"
          ? "Enable X-Y axis in zooming"
          : "Lock Zoom on X axis"
      }}
          </span>
        </v-tooltip>

        <SelectAxisBtn btnText="X" :items="normalDatasetFields" itemText="title" itemValue="name"
          :value="selectedAxis[0]" @onChange="onAxisChange(0, $event)"></SelectAxisBtn>
      </div>
      <div class="rightCol"></div>
    </div>
    <div v-if="!chart3DShow" class="d-flex flex-row justify-center pt-5">
      <div v-for="             l              in              legendInfo             " :key="l.label">
        <div class="d-flex flex-row mr-8 align-center" style="cursor: pointer" @click="onLegendClick(i)">
          <div class="mr-2" style="width: 21px; height: 21px" :style="'border: 1px solid grey; background-color:' +
        l.backgroundColor +
        ';border-radius:' +
        l.radius
        "></div>
          <div class="chartLegendText">{{ l.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProjection } from "@/api/user/dataset";
import { smartPruneNumber } from "@/utils/number";
import { gradient } from "@/utils/color";

import { Scatter } from "vue-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";

import CBtn from "@/components/Vuetify/CBtn.vue";
import GradientLegend from "@/components/Mat/GradientLegend.vue";
import SelectAxisBtn from "@/components/Mat/SelectAxisBtn.vue";
import ChartSettings from "@/components/Mat/ChartSettings.vue";
import ChartHelp from "@/components/Mat/ChartHelp.vue";
import App from "@/main";
import DChart from "../../components/3dChart/3dChart.vue";
import chartFilter from '../3dChart/chartFilter.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
} from "chart.js";


ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  zoomPlugin
);

const ORIG_SPACE_FILL_COLOR_LIGHT = "#00000014";
const ORIG_SPACE_FILL_COLOR_DARK = "#6B758839";
const ORIG_SPACE_BORDER_COLOR = "#9FA2AE80";
const BENCHMARK_SPACE_FILL_COLOR = "#F3C0E8";
const BENCHMARK_SPACE_BORDER_COLOR = "#f87979";
const DEFAULT_MARKER_FILL_COLOR = "#0393A8";
const DEFAULT_MARKER_BORDER_COLOR = "#86DAE5";
const DEFAULT_MARKER_SIZE = 5;
const DEFAULT_MARKER_THICKNESS = 1;

// border: 0.75px solid #86DAE5

export default {
  name: "DesignCastingChart",
  components: {
    CBtn,
    SelectAxisBtn,
    Scatter,
    ChartSettings,
    GradientLegend,
    ChartHelp,
    DChart,
    chartFilter,

  },

  props:
  {
    normalDatasetFields: {
      type: Array,
      default: () => {
        [];
      },
    },
    packageId: {
      type: Number,
      default: 0,
    },
    datasetId: {
      type: Number,
      default: 0,
    },
    standardDatasetId: {
      type: Number,
      default: null,
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: function () {
    return {
      rangex: [10, 50],
      actionSelectPoint: false,
      dataBack3DChart: {
        columnsFilter: [],
      },
      chart3DShow: false,
      chartConf3D: {},
      selectedAxis: [null, null, null],
      seriesIndices: {
        BENCHMAEK_SPACE_INDEX: 0,
        FILTERED_SERIES_INDEX: 1,
        ORIG_SPACE_INDEX: 2,
      },
      // gradientEnd:'#E3E4E4',

      markerFillColor: DEFAULT_MARKER_FILL_COLOR,
      markerBorderColor: DEFAULT_MARKER_BORDER_COLOR,
      markerSize: DEFAULT_MARKER_SIZE,
      makerThickness: DEFAULT_MARKER_THICKNESS,

      chartGeneralConfig: {
        chartId: "scatter-chart",
        datasetIdKey: "label",
        width: 400,
        height: 400,
        cssClasses: "",
        styles: { width: "96%", height: "97%" },
      },

      chartData: {
        datasets: [
          {
            label: "Benchmark Space",
            pointStyle: "rectRounded",
            fill: true,
            borderColor: BENCHMARK_SPACE_BORDER_COLOR,
            backgroundColor: BENCHMARK_SPACE_FILL_COLOR,
            data: [],
            radius: DEFAULT_MARKER_SIZE * 1.5,
            borderWidth: DEFAULT_MARKER_THICKNESS,
            borderDash: [],
            hoverRadius: DEFAULT_MARKER_SIZE * 1.8,
            hoverBorderWidth: DEFAULT_MARKER_THICKNESS + 1,
          },
          {
            label: "Filtered Space",
            fill: false,
            borderColor: DEFAULT_MARKER_BORDER_COLOR,
            backgroundColor: this.getPointBgColor,
            data: [],
            radius: DEFAULT_MARKER_SIZE,
            borderWidth: DEFAULT_MARKER_THICKNESS,
            borderDash: [],
            hoverRadius: DEFAULT_MARKER_SIZE * 1.3,
            hoverBorderWidth: DEFAULT_MARKER_THICKNESS + 1,
          },
          {
            label: "Original Space",
            fill: false,
            borderColor: ORIG_SPACE_BORDER_COLOR,
            backgroundColor: this.getOrigPointBgColor,
            data: [],
            radius: DEFAULT_MARKER_SIZE,
            borderWidth: DEFAULT_MARKER_THICKNESS,
            borderDash: [],
            hoverRadius: DEFAULT_MARKER_SIZE * 1.3,
            hoverBorderWidth: DEFAULT_MARKER_THICKNESS + 1,
          },
        ],
      },
      allChartData: [],

      //ZOOM
      zoomOptions: null,
      resetFlag: false,
      chartScales: {
        x: {
          gridLines: { color: "red" },
          grid: {
            display: true,
            drawOnChartArea: false,
            drawTicks: true,
            color: this.$vuetify.theme.themes.light.primary,
            borderColor: this.$vuetify.theme.themes.light.primary,
          },
          min: null,
          max: null,
        },
        y: {
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [4, 4],
            color: "#B6CBC440",
            // gridLines:{color:'red'},
          },
          min: null,
          max: null,
        },
      },
      lastPointMouseOver: null,
      zoomTimeoutId: null,
      zoomMode: "xy",

      plugins: [
        {
          id: "customCanvasBackgroundColor",
          beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            if (this.$vuetify.theme.isDark) {
              // ctx.fillStyle = options.color || '#F3F4F657';
              ctx.fillStyle = options.color || "#272A2F57";
            } else {
              ctx.fillStyle = options.color || "#F3F4F657";
            }
            ctx.fillRect(35, 10, chart.width - 50, chart.height - 40);
            ctx.restore();
          },
        },
        // {
        //   id: "corsair",
        //   afterInit: (chart) => {
        //     chart.corsair = {
        //       x: 0,
        //       y: 0,
        //     };
        //   },
        //   afterEvent: (chart, evt) => {
        //     const {
        //       chartArea: { top, bottom, left, right },
        //     } = chart;
        //     const {
        //       event: { x, y },
        //     } = evt;
        //     if (x < left || x > right || y < top || y > bottom) {
        //       chart.corsair = {
        //         x,
        //         y,
        //         draw: false,
        //       };
        //       chart.draw();
        //       return;
        //     }

        //     chart.corsair = {
        //       x,
        //       y,
        //       draw: true,
        //     };
        //     chart.draw();
        //   },
        //   afterDatasetsDraw: (chart, _, opts) => {
        //     const {
        //       ctx,
        //       chartArea: { top, bottom, left, right },
        //     } = chart;
        //     const { x, y, draw } = chart.corsair;

        //     if (!draw) {
        //       return;
        //     }

        //     ctx.lineWidth = opts.width || 0;
        //     ctx.setLineDash(opts.dash || []);
        //     ctx.strokeStyle = opts.color || "black";

        //     ctx.save();
        //     ctx.beginPath();
        //     ctx.moveTo(x, bottom);
        //     ctx.lineTo(x, top);
        //     ctx.moveTo(left, y);
        //     ctx.lineTo(right, y);
        //     ctx.stroke();
        //     ctx.restore();
        //   },
        // },
      ],
    };
  },
  computed: {
    gradientEnd() {
      return this.shadeHexColor(this.markerFillColor, 0.9);
    },
    legendInfo() {
      return this.chartData.datasets.map((item, idx) => {
        let x = {};
        x.label = item.label;
        x.backgroundColor =
          idx === this.seriesIndices.FILTERED_SERIES_INDEX
            ? this.markerFillColor
            : item.backgroundColor;
        x.radius =
          idx === this.seriesIndices.BENCHMAEK_SPACE_INDEX ? "35%" : "50%";
        return x;
      });
    },
    gradientLegendConfig() {
      if (
        this.axisInfo[2] !== null &&
        this.allChartData.aggregations[this.axisInfo[2].name]
      ) {
        return {
          min: this.allChartData.aggregations[this.axisInfo[2].name].min,
          max: this.allChartData.aggregations[this.axisInfo[2].name].max,
          title: this.axisInfo[2].title,
        };
      } else {
        return {
          min: 0,
          max: 0,
          title: null,
        };
      }
    },
    gradientLegendConfig3D() {
      if (
        this.axisInfo[3] &&
        this.axisInfo[3] !== null &&
        this.allChartData.aggregations[this.axisInfo[3].name]
      ) {
        return {
          min: this.allChartData.aggregations[this.axisInfo[3].name].min,
          max: this.allChartData.aggregations[this.axisInfo[3].name].max,
          title: this.axisInfo[3].title,
        };
      } else {
        return {
          min: 0,
          max: 0,
          title: null,
        };
      }
    },
    has3rdSeries() {
      return this.axisInfo[2] !== null;
    },
    axisInfo() {
      return this.selectedAxis.map((item) => {
        return item === null
          ? null
          : this.normalDatasetFields.find((x) => x.name === item);
      });
    },
    chartOptions() {
      return {
        interaction: {
          intersect: false,
          mode: "point",
        },
        onHover: this.onMouseOver,
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: this.chartScales,

        plugins: {
          corsair: {
            // dash: [2, 2],
            color: "#dddddd",
            width: 1,
          },
          tooltip: {
            enabled: false,
            external: this.tooltipFormatter,
          },
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              modifierKey: "alt",

              //   mode: function({ chart }) {
              //     return 'xy';
              //   },
              mode: "xy",

              // rangeMin: {
              //   // Format of min pan range depends on scale type
              //   x: null,
              //   y: null
              // },
              // rangeMax: {
              //   // Format of max pan range depends on scale type
              //   x: null,
              //   y: null
              // },

              // // On category scale, factor of pan velocity
              // speed: 20,

              // // Minimal pan distance required before actually applying pan
              // threshold: 10,

              // // Function called while the user is panning
              // onPan: function({chart}) { console.log(chart, `I'm panning!!!`); },
              // // Function called once panning is completed
              onPanComplete: this.onZoomed,
            },

            zoom: {
              wheel: {
                modifierKey: "ctrl",
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              drag: {
                enabled: true,
                borderColor: "rgb(54, 162, 235)",
                borderWidth: 1,
                backgroundColor: "rgba(54, 162, 235, 0.3)",
              },

              mode: this.getZoomMode,
              // mode: this.zoomMode,

              // rangeMin: {
              //   // Format of min zoom range depends on scale type
              //   x: null,
              //   y: null
              // },
              // rangeMax: {
              //   // Format of max zoom range depends on scale type
              //   x: null,
              //   y: null
              // },

              // Speed of zoom via mouse wheel
              // (percentage of zoom on a wheel event)
              // speed: 0.1,

              // // Minimal zoom distance required before actually applying zoom
              // threshold: 2,

              // // On category scale, minimal zoom level before actually applying zoom
              // sensitivity: 3,

              // // Function called while the user is zooming
              // onZoom: this.onZoomed,
              // // Function called once zooming is completed
              onZoomComplete: this.onZoomedHandler,
            },
            // limits:{ // OK
            //   x: {
            //     minRange: 10
            //   },
            //   y:{
            //     minRange: 10
            //   }
            // },
          },
        },
      };
    },
  },
  watch: {
    standardDatasetId() {
      console.log("NOW", this.standardDatasetId);
      this.resetDataChart();
      this.updateChart();
      // if(val){

      // }
    },
    // dataBack3DChart: {
    //   handler() {
    //     this.updateChart();
    //   },
    //   deep: true,
    // },
    packageId() {
      this.resetDataChart();
    },
    datasetId() {
      this.resetDataChart();
    },
  },
  methods: {
    resetChart() {
      this.selectedAxis = [null, null, null];
      this.chartData.datasets[this.seriesIndices.BENCHMAEK_SPACE_INDEX].data =
        [];
      this.chartData.datasets[this.seriesIndices.FILTERED_SERIES_INDEX].data =
        [];
      this.chartData.datasets[this.seriesIndices.ORIG_SPACE_INDEX].data = [];
      this.allChartData = [];
      this.zoomOptions = null;
      this.resetFlag = false;
      this.chartScales.x.min = null;
      this.chartScales.x.max = null;
      this.chartScales.y.min = null;
      this.chartScales.y.max = null;
      this.lastPointMouseOver = null;
      this.zoomTimeoutId = null;
      this.zoomMode = "xy";
    },
    resetDataChart() {
      this.axisInfo = {};
      this.allChartData = {};
      this.chart3DShow = false;
    },
    shadeHexColor(color, percent) {
      var f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00ff,
        B = f & 0x0000ff;
      return (
        "#" +
        (
          0x1000000 +
          (Math.round((t - R) * p) + R) * 0x10000 +
          (Math.round((t - G) * p) + G) * 0x100 +
          (Math.round((t - B) * p) + B)
        )
          .toString(16)
          .slice(1)
      );
    },
    getPointBgColor(context) {
      if (!this.has3rdSeries) {
        return this.markerFillColor;
      }
      var index = context.dataIndex;
      var z = context.dataset?.data[index]?.z; //FIXME: "TypeError: Cannot read properties of undefined (reading 'z')"

      const columns = this.axisInfo
        .filter((x) => x !== null)
        .map((item) => item.name);
      const zStat = this.allChartData.aggregations[columns[2]];
      // console.log(zStat)
      return (
        gradient(
          (z - zStat.min) / (zStat.max - zStat.min),
          this.markerFillColor,
          this.gradientEnd
        ) + "95"
      );
    },
    getOrigPointBgColor() {
      if (this.$vuetify.theme.isDark) {
        return ORIG_SPACE_FILL_COLOR_DARK;
      } else {
        return ORIG_SPACE_FILL_COLOR_LIGHT;
      }
    },
    onLegendClick(index) {
      const ci = this.$refs.myChart.chart;
      if (ci.isDatasetVisible(index)) {
        ci.hide(index);
      } else {
        ci.show(index);
      }
    },
    findDuplicatesWithLabels(arr) {
      const labels = ["X", "Y", "Z", "T"];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[j] !== null && arr[i] === arr[j]) {
              return [labels[i], labels[j]];
            }
          }
        }
      }
    },
    async onAxisChange(axis, value) {
      this.$set(this.selectedAxis, axis, value);
      console.log("this.selectedAxis", this.selectedAxis);
      let err = null;
      err = await this.findDuplicatesWithLabels(this.selectedAxis);

      if (err) {
        const errMessage = `${err[0]} and ${err[1]} axes are the same`;
        App.notify.show({ message: errMessage, type: "error" });
        return;
      }
      if (this.selectedAxis[0] !== null && this.selectedAxis[1] !== null) {
        this.updateChart();
      }
    },
    onApplyChartSetting(chartConf) {
      this.chartConf3D = chartConf;
      this.makerThickness = chartConf.makerThickness;
      this.markerBorderColor = chartConf.markerBorderColor;
      this.markerFillColor = chartConf.markerFillColor;
      this.markerSize = chartConf.markerSize;

      this.chartData.datasets[this.seriesIndices.FILTERED_SERIES_INDEX].radius =
        this.markerSize;
      this.chartData.datasets[
        this.seriesIndices.FILTERED_SERIES_INDEX
      ].borderWidth = this.makerThickness;
      this.chartData.datasets[
        this.seriesIndices.FILTERED_SERIES_INDEX
      ].borderColor = this.markerBorderColor;
      (this.chartData.datasets[
        this.seriesIndices.FILTERED_SERIES_INDEX
      ].backgroundColor = this.getPointBgColor),
        (this.chartData.datasets[
          this.seriesIndices.FILTERED_SERIES_INDEX
        ].hoverRadius = this.markerSize * 1.3),
        (this.chartData.datasets[
          this.seriesIndices.FILTERED_SERIES_INDEX
        ].hoverBorderWidth = this.makerThickness + 1);

      this.chartData.datasets[this.seriesIndices.BENCHMAEK_SPACE_INDEX].radius =
        this.markerSize * 1.5;
      (this.chartData.datasets[
        this.seriesIndices.BENCHMAEK_SPACE_INDEX
      ].hoverRadius = this.markerSize * 1.8),
        (this.chartData.datasets[
          this.seriesIndices.BENCHMAEK_SPACE_INDEX
        ].hoverBorderWidth = this.makerThickness + 1);

      this.chartData.datasets[this.seriesIndices.ORIG_SPACE_INDEX].radius =
        this.markerSize;
      this.chartData.datasets[this.seriesIndices.ORIG_SPACE_INDEX].borderWidth =
        this.makerThickness;
      (this.chartData.datasets[
        this.seriesIndices.ORIG_SPACE_INDEX
      ].hoverRadius = this.markerSize * 1.3),
        (this.chartData.datasets[
          this.seriesIndices.ORIG_SPACE_INDEX
        ].hoverBorderWidth = this.makerThickness + 1);

      this.$refs.myChart.chart.update();
    },
    onSetNewRange(newData) {
      console.log("newData", newData);
    },
    onResetZoom() {
      this.zoomOptions = null;
      this.chartScales.x.min = null;
      this.chartScales.x.max = null;
      this.chartScales.y.min = null;
      this.chartScales.y.max = null;
      // this.chartScales = {
      //   x: {
      //     min: null,
      //     max: null,
      //   },
      //   y: {
      //     min: null,
      //     max: null,
      //   },
      // };
      this.updateChart(true);
    },
    updateChart(doReset = false) {
      console.log("step", this.dataBack3DChart);
      if (
        this.axisInfo[0] == null ||
        this.axisInfo[1] == null ||
        this.axisInfo[0] == this.axisInfo[1] ||
        this.axisInfo[0] == this.axisInfo[2] ||
        this.axisInfo[1] == this.axisInfo[2]
      ) {
        return;
      }
      const columns = this.axisInfo
        .filter((x) => x !== null)
        .map((item) => item.name);
      const _this = this;
      let start = Date.now() / 1000;
      let filters = Object.keys(this.filters).map((item) => {
        let m = {};
        m[item] = this.filters[item];
        return m;
      });
      // console.log(doReset)
      let columnFilters = [];
      if (!this.chart3DShow) {
        if (this.zoomOptions?.xaxis) {
          let x = {};
          x[columns[0]] = {
            lte: this.zoomOptions.xaxis.max,
            gte: this.zoomOptions.xaxis.min,
          };
          columnFilters.push(x);
        }
        if (this.zoomOptions?.yaxis) {
          let y = {};
          y[columns[1]] = {
            lte: this.zoomOptions.yaxis.max,
            gte: this.zoomOptions.yaxis.min,
          };
          columnFilters.push(y);
        }
      } else {
        if (!doReset) {
          columnFilters = this.dataBack3DChart?.columnsFilter || [];
        } else {
          this.actionSelectPoint = false;
          this.dataBack3DChart.columnsFilter = [];
        }
      }
      console.log("INSIDE-CHART", this.standardDatasetId);
      getProjection(
        this.packageId,
        this.datasetId,
        columns,
        filters,
        columnFilters,
        this.standardDatasetId,
        function (res) {
          _this.allChartData = res;
          // console.log("res ", res);
          const columns = _this.axisInfo
            .filter((x) => x !== null)
            .map((item) => item.name);
          const columns_avg = columns.map((item) => item + "_v");
          const columns_min = columns.map((item) => item + "_n");
          const columns_max = columns.map((item) => item + "_x");
          _this.chartData.datasets[_this.seriesIndices.ORIG_SPACE_INDEX].data =
            [];
          if (_this.allChartData.origResult) {
            _this.chartData.datasets[
              _this.seriesIndices.ORIG_SPACE_INDEX
            ].data = _this.allChartData.origResult.map((item) => {
              return {
                x: item[columns_avg[0]],
                y: item[columns_avg[1]],
                z: item[columns_avg[2]] || 0,
                t: item[columns_avg[3]] || 0,
                min_x: item[columns_min[0]],
                min_y: item[columns_min[1]],
                min_z: item[columns_min[2]] || 0,
                min_t: item[columns_min[3]] || 0,
                max_x: item[columns_max[0]],
                max_y: item[columns_max[1]],
                max_z: item[columns_max[2]] || 0,
                max_t: item[columns_max[3]] || 0,
              };
            });
          }
          _this.chartData.datasets[
            _this.seriesIndices.BENCHMAEK_SPACE_INDEX
          ].data = [];
          if (_this.allChartData.standardDatasetRes) {
            _this.chartData.datasets[
              _this.seriesIndices.BENCHMAEK_SPACE_INDEX
            ].data = _this.allChartData.standardDatasetRes.map((item) => {
              return {
                x: item[_this.axisInfo[0].name],
                y: item[_this.axisInfo[1].name],
                z: item[_this.axisInfo[2]?.name] || 0,
                t: item[_this.axisInfo[3]?.name] || 0,
                min_x: item[_this.axisInfo[0].name],
                min_y: item[_this.axisInfo[1].name],
                min_z: item[_this.axisInfo[2]?.name] || 0,
                min_t: item[_this.axisInfo[3]?.name] || 0,
                max_x: item[_this.axisInfo[0].name],
                max_y: item[_this.axisInfo[1].name],
                max_z: item[_this.axisInfo[2]?.name] || 0,
                max_t: item[_this.axisInfo[3]?.name] || 0,
              };
            });
          }
          _this.chartData.datasets[
            _this.seriesIndices.FILTERED_SERIES_INDEX
          ].data = [];
          if (_this.allChartData.result) {
            _this.chartData.datasets[
              _this.seriesIndices.FILTERED_SERIES_INDEX
            ].data = _this.allChartData.result.map((item) => {
              return {
                x: item[columns_avg[0]],
                y: item[columns_avg[1]],
                z: item[columns_avg[2]] || 0,
                t: item[columns_avg[3]] || 0,
                min_x: item[columns_min[0]],
                min_y: item[columns_min[1]],
                min_z: item[columns_min[2]] || 0,
                min_t: item[columns_min[3]] || 0,
                max_x: item[columns_max[0]],
                max_y: item[columns_max[1]],
                max_z: item[columns_max[2]] || 0,
                max_t: item[columns_max[3]] || 0,
              };
            });
          }
          if (doReset) {
            _this.$refs.myChart.chart.resetZoom();
          }
          console.log("Elaspsed", Date.now() / 1000 - start);
        }
      );
    },
    onMouseOver(event, points) {
      // console.log(event.type, points)
      if (event.type != "mousemove" || points.length == 0) {
        return;
      }
      const index = points[0].index;
      const dataIndex = points[0].datasetIndex;
      const dt = this.chartData.datasets[dataIndex].data[index];
      if (dt == this.lastPointMouseOver) {
        return;
      }
      this.lastPointMouseOver = dt;
      const x = {},
        y = {};
      x[this.axisInfo[0].name] = { lte: dt.max_x, gte: dt.min_x };
      y[this.axisInfo[1].name] = { lte: dt.max_y, gte: dt.min_y };
      let localFilters = [x, y];
      if (this.axisInfo[2]) {
        const z = {};
        z[this.axisInfo[2].name] = { lte: dt.max_z, gte: dt.min_z };
        localFilters.push(z);
      }
      const _this = this;
      // console.log('localFilters', localFilters);
      _this.$emit("onMarkerMouseOver", localFilters);
    },
    onMouseOver3D(data) {
      this.$emit("onMarkerMouseOver", data);
    },
    onZoomedHandler(chart) {
      // console.log('CALLED')
      clearTimeout(this.zoomTimeoutId);
      this.zoomTimeoutId = setTimeout(this.onZoomed, 100, chart);
    },
    onZoomed(chart) {
      // console.log(new Date().toJSON())
      // console.log(chart.chart.scales.x.min, chart.chart.scales.x.max, chart.chart.scales.y.min, chart.chart.scales.y.max,`I'm zooming!!!`);
      if (this.resetFlag) {
        this.resetFlag = false;
        this.updateChart();
      } else {
        this.zoomOptions = {
          xaxis: {
            min: chart.chart.scales.x.min,
            max: chart.chart.scales.x.max,
          },
          yaxis: {
            min: chart.chart.scales.y.min,
            max: chart.chart.scales.y.max,
          },
        };
        this.updateChart();
      }
    },

    // eslint-disable-next-line no-unused-vars
    tooltipFormatter(context) {
      // console.log(context.tooltip)

      let tooltipEl = document.getElementById("chartjs-tooltip");
      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML = "<div></div>";
        document.body.appendChild(tooltipEl);
      }
      // Hide if no tooltip
      const tooltipModel = context.tooltip;
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      const dataPoint = context.tooltip.dataPoints[0];
      // const seriesName = dataPoint.dataset.label
      var data = dataPoint.dataset.data[dataPoint.dataIndex];
      let x_jitter = smartPruneNumber((data.max_x - data.min_x) / 2);
      let y_jitter = smartPruneNumber((data.max_y - data.min_y) / 2);
      let z_jitter = this.axisInfo[2]
        ? smartPruneNumber((data.max_z - data.min_z) / 2)
        : 0;
      let containerClass = "customizedTooltip";
      if (this.$vuetify.theme.isDark) {
        containerClass += " dark";
      }
      let res =
        '<div class="' +
        containerClass +
        '" >' +
        '<div class="customizedTooltipRow"> ' +
        '<div class="v-icon notranslate mdi mdi-axis-x-arrow customizedTooltipIcon"  ></div><div class="customizedTooltipItem ">' +
        this.axisInfo[0].title +
        ' </div><div class="customizedTooltipItemValue">' +
        smartPruneNumber(data.x) +
        '</div><div class="customizedTooltipItemJitter">' +
        (x_jitter != 0 ? "(±" + x_jitter + ")" : "") +
        "</div>" +
        "</div>" +
        '<div class="customizedTooltipRow"> ' +
        '<div class="v-icon notranslate mdi mdi-axis-y-arrow customizedTooltipIcon"  ></div><div class="customizedTooltipItem">' +
        this.axisInfo[1].title +
        ' </div><div class="customizedTooltipItemValue number">' +
        smartPruneNumber(data.y) +
        '</div><div class="customizedTooltipItemJitter">' +
        (y_jitter != 0 ? "(±" + y_jitter + ")" : "") +
        "</div>" +
        "</div>" +
        (this.axisInfo[2]
          ? '<div class="customizedTooltipRow"><div class="v-icon notranslate mdi mdi-axis-z-arrow customizedTooltipIcon"  ></div><div class="customizedTooltipItem">' +
          this.axisInfo[2].title +
          ' </div><div class="customizedTooltipItemValue number">' +
          smartPruneNumber(data.z) +
          '</div><div class="customizedTooltipItemJitter">' +
          (z_jitter != 0 ? "(±" + z_jitter + ")" : "") +
          "</div></div>"
          : "") +
        "</div>";

      if (tooltipModel.body) {
        let tableRoot = tooltipEl.querySelector("div");
        tableRoot.innerHTML = res;
      }

      const position = context.chart.canvas.getBoundingClientRect();
      // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX + "px";
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + "px";
      // tooltipEl.style.font = bodyFont.string;
      tooltipEl.style.padding =
        tooltipModel.padding + "px " + tooltipModel.padding + "px";
      tooltipEl.style.pointerEvents = "none";
    },

    swapXY() {
      const x = this.selectedAxis[0];
      const y = this.selectedAxis[1];
      const z = this.selectedAxis[2];
      this.selectedAxis = [y, x, z];
      const scales = Object.assign({}, this.chartScales);
      scales.x.min = this.$refs.myChart.chart.scales.y.min;
      scales.x.max = this.$refs.myChart.chart.scales.y.max;
      scales.y.min = this.$refs.myChart.chart.scales.x.min;
      scales.y.max = this.$refs.myChart.chart.scales.x.max;

      this.chartScales = scales;

      if (this.zoomOptions !== null) {
        this.zoomOptions = {
          xaxis: {
            min: this.chartScales.x.min,
            max: this.chartScales.x.max,
          },
          yaxis: {
            min: this.chartScales.y.min,
            max: this.chartScales.y.max,
          },
        };
      }
      this.updateChart();
    },
    getZoomMode() {
      return this.zoomMode;
    },
  },
};
</script>
<style lang="scss">
.rightCol {
  width: 90px;
  // padding-left: auto;
  // margin-left: 5px;
}

.leftCol {
  width: 40px;
  margin-right: 10px;
}

.centerCol {
  width: 100%;
}

.centerRow {
  height: 100%;
}

.yAxisLabelBox {
  width: 34px;
  background-color: #f6f6f6;
}

.theme--dark .yAxisLabelBox {
  background-color: #272a2f;
  color: #ebeafa !important;
}

.yAxisLabel {
  /* display: block; */
  white-space: nowrap;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  font-weight: 500;
  font-size: 14px;
  transform: rotate(180deg);

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.xAxisLabelBox {
  height: 34px;
  width: 100%;
  background-color: #f6f6f6;
  text-align: center;
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
}

.theme--dark .xAxisLabelBox {
  background-color: #272a2f;
  color: #ebeafa !important;
}

.customizedTooltip {
  // z-index: 2;
  // border-image: linear-gradient(to right,#9ADBE4, #0393A8) 20;
  border-width: 0.5px;
  border-style: solid;
  margin-top: 10px;
  margin-left: 10px;

  border-color: var(--v-primary-base);
  border-radius: 6px;

  min-width: 320px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(3px);
  color: black;
  padding: 6px;
  font-family: KoHo, sans-serif;
  font-size: 12px !important;
}

.customizedTooltip.dark {
  color: white;
  background: #000000cc;
  box-shadow: none;
}

.customizedTooltipRow {
  display: flex;
  padding-top: 3px;
}

.customizedTooltipItem {
  font-weight: 400;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 4px;
  margin-right: auto;
}

.customizedTooltipIcon {
  font-size: 14px !important;
  color: var(--v-primary-base);
}

.customizedTooltipItemValue {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3px;
  font-weight: 700;
  color: var(--v-primary-base);
}

.customizedTooltipItemJitter {
  font-size: 10px !important;
  color: #6b7588;
  width: 60px;
  padding-top: 5px;
  padding-left: 4px;
}

.zoomModeBtn {
  border-radius: 8px;
  padding-left: 7px;
  padding-top: 2px;
  width: 32px;
  height: 32px;
  color: black;
  background-color: #f2f4f7;
  cursor: pointer;
}

.theme--dark .zoomModeBtn {
  background-color: #272a2f;
  color: white;
}

.zoomModeBtn:hover {
  background-color: #d8d8da;
}

.theme--dark .zoomModeBtn:hover {
  background-color: #37393b;
}

.theme--light .swapBtn {
  color: black !important;
  background-color: #f2f4f7 !important;
}

.theme--dark .swapBtn {
  background-color: #272a2f !important;
  color: white !important;
}

.chartLegendText {
  font-size: 14px;
  color: #615e83;
  vertical-align: center;
}

.theme--dark .chartLegendText {
  color: #9fa2ae;
}

.switch-3d {
  margin-top: -5px;

  padding-top: 0px;
}

/* .zoomModeBtnPressed{
  background-color: grey;
} */
</style>
